<template>
    <div class="container-fluid project py-5 my-5">
      <div class="container py-5">
        <div class="text-center mx-auto pb-5" style="max-width: 600px;">
          <h5 class="text-primary">Our Project</h5>
          <h1>Our Recently Completed Projects</h1>
        </div>
        <div class="row g-5">
          <div v-for="(project, index) in projects" :key="index" class="col-md-6 col-lg-4">
            <div class="project-item">
              <div class="project-img">
                <img :src="project.imageUrl" class="img-fluid w-100 rounded" alt="">
                <div class="project-content">
                  <router-link :to="project.link" class="text-center">
                    <h4 class="text-secondary">{{ project.title }}</h4>
                    <p class="m-0 text-white">{{ project.subtitle }}</p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Projects_C',
    data() {
      return {
        projects: [
  { title: 'Web Design', subtitle: 'Web Analysis', imageUrl: 'img/project-1.jpg', link: '#' },
  { title: 'Cyber Security', subtitle: 'Cyber Security Core', imageUrl: 'img/project-2.jpg', link: '#' },
  { title: 'Mobile Info', subtitle: 'Upcoming Phone', imageUrl: 'img/project-3.jpg', link: '#' },
  { title: 'Web Development', subtitle: 'Web Analysis', imageUrl: 'img/project-4.jpg', link: '#' },
  { title: 'Digital Marketing', subtitle: 'Marketing Analysis', imageUrl: 'img/project-5.jpg', link: '#' },
  { title: 'Keyword Research', subtitle: 'Keyword Analysis', imageUrl: 'img/project-6.jpg', link: '#' }
]

      }
    }
  }
  </script>
  
  <style scoped>
  /* Your CSS here */
  </style>
  