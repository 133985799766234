<template>
  <header>
    <!-- Topbar -->
    <div class="container-fluid bg-dark py-2 d-none d-md-flex">
      <div class="container">
        <div class="d-flex justify-content-between topbar">
          <div class="top-info">
            <small class="me-3 text-white-50">
              <a href="#">
                <i class="fas fa-envelope me-2 text-secondary"></i>
                <small>NativeProgrammers@gmail.com</small>
              </a>
            </small>
          </div>
          <!-- Note Section for Scrolling Mission and Vision -->
          <div id="note" class="text-secondary d-none d-xl-flex align-items-center overflow-hidden">
           
            <small v-html="currentNote" class=" scrolling-text description-text"></small>
          </div>
          <div class="top-link">
            <a href="https://www.facebook.com/NativeProgrammers" class="bg-light nav-fill btn btn-sm-square rounded-circle">
              <i class="fab fa-facebook-f text-primary"></i>
            </a>
            <a href="#" class="bg-light nav-fill btn btn-sm-square rounded-circle">
              <i class="fab fa-twitter text-primary"></i>
            </a>
            <a href="#" class="bg-light nav-fill btn btn-sm-square rounded-circle">
              <i class="fab fa-instagram text-primary"></i>
            </a>
            <a href="https://www.linkedin.com/company/nativeprogrammerss" class="bg-light nav-fill btn btn-sm-square rounded-circle me-0">
              <i class="fab fa-linkedin-in text-primary"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Main Navbar -->
    <div class="container-fluid bg-primary">
      <div class="container">
        <nav class="navbar navbar-dark navbar-expand-lg py-0">
          <router-link to="/" class="navbar-brand">
            <h1 class="text-white fw-bold d-block">Native<span class="text-secondary">Programmers</span></h1>
          </router-link>
          <button type="button" class="navbar-toggler me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse bg-transparent" id="navbarCollapse">
            <div class="navbar-nav ms-auto mx-xl-auto p-0">
              <router-link to="/" class="nav-item nav-link active text-secondary">Home</router-link>
              <router-link to="/about" class="nav-item nav-link">About</router-link>
              <router-link to="/services" class="nav-item nav-link">Services</router-link>
              <router-link to="/projects" class="nav-item nav-link">Projects</router-link>
              <router-link to="/contact" class="nav-item nav-link">Contact</router-link>
            </div>
          </div>
          <div class="d-none d-xl-flex flex-shrink-0">
            <div id="phone-tada" class="d-flex align-items-center justify-content-center me-4">
              <a href="#" class="position-relative animated tada infinite">
                <i class="fa fa-phone-alt text-white fa-2x"></i>
                <div class="position-absolute" style="top: -7px; left: 20px;">
                  <span><i class="fa fa-comment-dots text-secondary"></i></span>
                </div>
              </a>
            </div>
            <div class="d-flex flex-column pe-4 border-end">
              <span class="text-white-50">Have any questions?</span>
              <span class="text-white">Call: + 92 311 567 1234</span>
            </div>
            <div class="d-flex align-items-center justify-content-center ms-4">
              <a href="#"><i class="bi bi-search text-white fa-2x"></i></a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Navbar_C',
  data() {
    return {
      notes: [
  `<strong>Our Mission:</strong> To empower businesses through innovative software solutions that are reliable, scalable, and tailored to meet unique needs.
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp     
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp     
    <strong>Our Vision:</strong> To be a leader in the software development industry, known for our commitment to quality, innovation, and client satisfaction.`,
],

      currentNoteIndex: 0,
      currentNote: '',
    };
  },
  mounted() {
    this.currentNote = this.notes[this.currentNoteIndex];
    // setInterval(() => {
    //   this.toggleNote();
    // }, 21000); // Change every 10 seconds for a longer scroll
  },
  methods: {
    // toggleNote() {
    //   this.currentNoteIndex = (this.currentNoteIndex + 1) % this.notes.length;
    //   this.currentNote = this.notes[this.currentNoteIndex];
    // },
  },
};
</script>

<style scoped>
/* Scoped styles for your Navbar */




</style>
