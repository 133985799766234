<template>
  <div class="container-fluid py-5 my-5">
    <div class="container pt-5">
      <div class="row g-5 align-items-center">
        <!-- Image Section -->
        <div class="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
          <div class="h-100 position-relative">
            <img src="img/about-1.jpg" class="img-fluid w-75 rounded mb-4" alt="Our Mission">
            <div class="position-absolute w-75" style="top: 25%; left: 25%;">
              <img src="img/about-2.jpg" class="img-fluid w-100 rounded" alt="Our Team">
            </div>
          </div>
        </div>

        <!-- About Us Section -->
        <div class="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
          <h5 class="text-primary">About Us</h5>
          <h1 class="mb-4">Innovative Solutions by NativeProgrammers</h1>
          <p>
            At NativeProgrammers, we believe that innovation drives success. As a growing software development company, we are committed to delivering customized, high-quality software solutions that empower businesses to succeed in a rapidly evolving digital landscape.
          </p>
          <p>
            Our approach is simple yet effective: understand the client's vision, harness our expertise in technology, and deliver solutions that exceed expectations. Join us on this journey as we bring innovative ideas to life!
          </p>
          <!-- Call to Action Button -->
          <router-link to="/about" class="btn btn-secondary rounded-pill px-5 py-3 text-white">Learn More About Us</router-link>
        </div>
      </div>

      <!-- Mission, Vision, and Core Values Section -->
      <div class="row g-5 mt-5">
        <div
          v-for="(service, index) in services"
          :key="index"
          class="col-lg-4 col-md-6 col-sm-12 wow fadeIn"
          :data-wow-delay="service.delay"
        >
          <div class="service-item text-center p-4 d-flex flex-column justify-content-between">
            <!-- Icon with Styling -->
            <div class="icon-container mb-3">
              <i :class="service.icon + ' fa-4x text-primary'"></i>
            </div>
            <!-- Title and Description -->
            <div>
              <h4 class="mb-3 font-weight-bold service-title">{{ service.title }}</h4>
              <p v-html="service.description" class="text-right description-text"></p> <!-- Right-aligned text -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutSection',
  data() {
    return {
      services: [
        {
          icon: 'fas fa-lightbulb', // Updated icon for Mission
          title: 'Our Mission',
          description:
            'To empower businesses through innovative software solutions that are reliable, scalable, and tailored to meet unique needs. We aim to help our clients navigate the digital landscape with confidence and efficiency.',
          delay: '.3s'
        },
        {
          icon: 'fas fa-bullseye', // Updated icon for Vision
          title: 'Our Vision',
          description:
            'To be a leader in the software development industry, known for our commitment to quality, innovation, and client satisfaction. We envision a future where NativeProgrammers is synonymous with digital transformation and technological excellence.',
          delay: '.5s'
        },
        {
          icon: 'fas fa-handshake', // Updated icon for Core Values
          title: 'Core Values',
          description: `
            <ul class="list-unstyled">
              <li><strong>Innovation:</strong> Continuously pushing boundaries to deliver cutting-edge solutions.</li>
              <li><strong>Quality:</strong> Committed to delivering high standards in every project.</li>
              <li><strong>Integrity:</strong> Building trust through transparency and honesty.</li>
              <li><strong>Customer Focus:</strong> Prioritizing client needs to ensure satisfaction and long-term success.</li>
            </ul>`,
          delay: '.7s'
        }
      ]
    };
  },
  mounted() {
    if (typeof WOW !== 'undefined') {
      new WOW().init();
    }
  }
};
</script>

<style scoped>
.service-item {
  height: 400px; /* Fixed height for consistency */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Align content */
  transition: transform 0.3s ease;
  background-color: #f8f9fa; /* Light background */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  overflow: hidden; /* Prevent overflow */
  padding: 20px; /* Padding for inner spacing */
}
.service-item:hover {
  transform: translateY(-10px); /* Hover effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}
.icon-container {
  background-color: #e0f7fa; /* Icon background color */
  border-radius: 50%; /* Circular background for icon */
  padding: 15px; /* Padding around icon */
  display: inline-block; /* Keep icon centered */
}
.service-title {
  color: #333; /* Darker title color */
  margin-top: 20px; /* Space above the title */
  height: 60px; /* Fixed height for all titles */
  display: flex; /* Flexbox for alignment */
  align-items: center; /* Vertically center the title */
  justify-content: center; /* Horizontally center the title */
}
.description-text {
  text-align: left; /* left-align text */
  margin-top: 10px; /* Space above the description */
}
p {
  font-size: 0.95rem; /* Slightly smaller font size for descriptions */
  line-height: 1.6; /* Better line spacing for readability */
}
</style>
