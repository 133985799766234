<template>
    <div class="container-fluid services py-5 mb-5">
      <div class="container">
        <div class="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style="max-width: 600px;">
          <h5 class="text-primary">Our Services</h5>
          <h1>Services Built Specifically For Your Business</h1>
        </div>
        <div class="row g-5 services-inner">
          <div 
            v-for="(service, index) in services" 
            :key="index" 
            class="col-md-6 col-lg-4 wow fadeIn" 
            :data-wow-delay="service.delay"
          >
            <div class="services-item bg-light">
              <div class="p-4 text-center services-content">
                <div class="services-content-icon">
                  <i :class="service.icon + ' fa-7x mb-4 text-primary'"></i>
                  <h4 class="mb-3">{{ service.title }}</h4>
                  <p class="mb-4">{{ service.description }}</p>
                  <a href="#" class="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // import WOW from 'wow.js';
  export default {
    name: 'ServicesSection',
    data() {
      return {
        services: [
  {
    icon: 'fas fa-paint-brush',
    title: 'Web Design',
    description: 'Craft visually stunning and user-friendly designs tailored to your brand and audience.',
    delay: '.3s'
  },
  {
    icon: 'fas fa-code',
    title: 'Web Development',
    description: 'Develop high-performance websites with custom functionality to meet diverse business needs.',
    delay: '.5s'
  },
  {
    icon: 'fas fa-object-ungroup',
    title: 'UI/UX Design',
    description: 'Enhance user satisfaction with efficient, intuitive, and accessible design interfaces.',
    delay: '.5s'
  },
  {
    icon: 'fas fa-bullhorn',
    title: 'Digital Marketing',
    description: 'Expand your reach and increase engagement with targeted digital marketing strategies.',
    delay: '.5s'
  },
  {
    icon: 'fas fa-laptop-code',
    title: 'Programming',
    description: 'Custom software solutions, from desktop applications to enterprise systems, using the latest technologies.',
    delay: '.5s'
  },
  {
    icon: 'fas fa-mobile-alt',
    title: 'Mobile Development',
    description: 'Create responsive and robust mobile applications for both Android and iOS platforms.',
    delay: '.5s'
  },
  // ...add other services here
]

      };
    },
    mounted() {
      // If WOW.js is needed for initialization of animations
      if (typeof WOW !== 'undefined') {
        new WOW().init();
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add your scoped CSS here */
  </style>
  