import { createApp } from 'vue';
import App from './App.vue';
import router from './router';



import '../public/css/style.css';




const app = createApp(App);

// Use Vue Router
app.use(router);

// Initialize WOW.js with a directive
app.directive('wow', {
  mounted() {
    new WOW().init();
  }
});

app.mount('#app');
