<template>
  <footer class="container-fluid footer bg-dark wow fadeIn" data-wow-delay=".3s">
    <div class="container pt-5 pb-4">
      <div class="row g-5">
        <div class="col-lg-3 col-md-6">
          <router-link to="/">
            <h1 class="text-white fw-bold d-block">Native<br /><span class="text-secondary">Programmers</span></h1>
          </router-link>
          <p class="mt-4 text-light">{{ description }}</p>
          <div class="d-flex hightech-link">
            <a href="https://www.facebook.com" target="_blank"
              class="btn-light nav-fill btn btn-square rounded-circle me-2" rel="noopener noreferrer"><i
                class="fab fa-facebook-f text-primary"></i></a>
            <a href="https://www.twitter.com" target="_blank"
              class="btn-light nav-fill btn btn-square rounded-circle me-2" rel="noopener noreferrer"><i
                class="fab fa-twitter text-primary"></i></a>
            <a href="https://www.instagram.com" target="_blank"
              class="btn-light nav-fill btn btn-square rounded-circle me-2" rel="noopener noreferrer"><i
                class="fab fa-instagram text-primary"></i></a>
            <a href="https://www.linkedin.com" target="_blank"
              class="btn-light nav-fill btn btn-square rounded-circle me-0" rel="noopener noreferrer"><i
                class="fab fa-linkedin-in text-primary"></i></a>

          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <h3 class="h3 text-secondary">Short Link</h3>
          <div class="mt-4 d-flex flex-column short-link">
            <router-link to="/about" class="mb-2 text-white">
              <i class="fas fa-angle-right text-secondary me-2"></i>About us
            </router-link>
            <router-link to="/contact" class="mb-2 text-white">
              <i class="fas fa-angle-right text-secondary me-2"></i>Contact us
            </router-link>
            <router-link to="/services" class="mb-2 text-white">
              <i class="fas fa-angle-right text-secondary me-2"></i>Our Services
            </router-link>
            <router-link to="/projects" class="mb-2 text-white">
              <i class="fas fa-angle-right text-secondary me-2"></i>Our Projects
            </router-link>
            <router-link to="/blog" class="mb-2 text-white">
              <i class="fas fa-angle-right text-secondary me-2"></i>Latest Blog
            </router-link>

          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <h3 class="h3 text-secondary">Help Link</h3>
          <div class="mt-4 d-flex flex-column help-link">
            <router-link to="/terms-of-use" class="mb-2 text-white">
              <i class="fas fa-angle-right text-secondary me-2"></i>Terms Of Use
            </router-link>
            <router-link to="/privacy-policy" class="mb-2 text-white">
              <i class="fas fa-angle-right text-secondary me-2"></i>Privacy Policy
            </router-link>
            <router-link to="/helps" class="mb-2 text-white">
              <i class="fas fa-angle-right text-secondary me-2"></i>Helps
            </router-link>
            <router-link to="/faqs" class="mb-2 text-white">
              <i class="fas fa-angle-right text-secondary me-2"></i>FAQs
            </router-link>
            <router-link to="/contact" class="mb-2 text-white">
              <i class="fas fa-angle-right text-secondary me-2"></i>Contact
            </router-link>

          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <h3 class="h3 text-secondary">Contact Us</h3>
          <div class="text-white mt-4 d-flex flex-column contact-link">
            <a href="#" class="pb-3 text-light border-bottom border-primary"><i
                class="fas fa-map-marker-alt text-secondary me-2"></i> Lahore, Punjab, Pakistan</a>
            <a href="#" class="py-3 text-light border-bottom border-primary"><i
                class="fas fa-phone-alt text-secondary me-2"></i> +92 311 567 1234</a>
            <a href="#" class="py-3 text-light border-bottom border-primary"><i
                class="fas fa-envelope text-secondary me-2"></i> nativeprogrammers@gmail.com</a>
          </div>
        </div>
      </div>
      <hr class="text-light mt-5 mb-4">
      <div class="row">
        <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
          <span class="text-light">&copy; 2024 Native Programmers, All right reserved.</span>
        </div>
        <div class="col-md-6 text-center text-md-end">
          <span class="text-light">Designed By <a href="https://nativeprogrammers.com" class="text-secondary">Native Programmers</a></span>
          
        
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer_C',
  // If needed, you can add script content here
  data() {
    return {
      // Example dynamic description. Replace this with actual data or props as needed.
      description: 'Empowering your digital needs with innovative solutions tailored for success.'
    };
  }
};
</script>

<style scoped>
.branding h1 {
  font-size: 2.5rem; /* Example size, adjust as needed */
}

.branding p {
  font-size: 1.2rem; /* Consistent font sizing */
}
</style>