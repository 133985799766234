<template>
    <div >
        <div class="container-fluid page-header py-5">
            <div class="container text-center py-5">
                <h1 class="display-2 text-white mb-4 animated slideInDown">Contact Us</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                    <ol class="breadcrumb justify-content-center mb-0">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <!-- <li class="breadcrumb-item"><a href="#">Pages</a></li> -->
                        <li class="breadcrumb-item" aria-current="page">Contact</li>
                    </ol>
                </nav>
            </div>
        </div>
  <Fact/>
  
  <contact/>

    </div>
  </template>
  
  <script>

  import Fact from '../components/compo/Fact_C.vue'

  import contact from '../components/compo/contact_C.vue'


  
  
  
  export default {
    name: 'home_p',
    components: {
      Fact,contact
     
    } 
  }
  </script>
  
  <style scoped>
  
  </style>