<template>
  <div id="app">
    <Navbar/>
    <router-view/> <!-- Place where routed components will be rendered -->
    <Footer/>
  </div>
</template>

<script>
import Navbar from './components/compo/Navbar_C.vue'
import Footer from './components/compo/Footer_C.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  }
}
</script>

<style lang="scss">
@import "@/scss/bootstrap.scss"; 
</style>
