<template>
    <div class="container-fluid py-5 mt-5">
      <div class="container py-5">
        <div class="text-center mx-auto pb-5" style="max-width: 600px;">
          <h5 class="text-primary">Get In Touch</h5>
          <h1 class="mb-3">Contact for any query</h1>
          <p class="mb-2">
            The contact form is currently inactive. Get a functional and working contact form with Ajax & PHP in a few minutes. Just copy and paste the files, add a little code and you're done. 
            
          </p>
        </div>
        <div class="contact-detail position-relative p-5">
          <div class="row g-5 mb-5 justify-content-center">
            <!-- Contact Information -->
            <div v-for="(contact, index) in contactInfo" :key="index" class="col-xl-5 col-lg-6">
              <div class="d-flex bg-light p-3 rounded">
                <div class="flex-shrink-0 btn-square bg-secondary rounded-circle" style="width: 64px; height: 64px;">
                  <i :class="contact.iconClass"></i>
                </div>
                <div class="ms-3">
                  <h4 class="text-primary">{{ contact.type }}</h4>
                  <a :href="contact.link" target="_blank" class="h5">{{ contact.value }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-5">
            <!-- Contact Map -->
            <div class="col-lg-6">
              <div class="p-5 h-100 rounded contact-map">
                <!-- The map can be optionally controlled by Vue, for example updating the src based on user interaction -->
                <iframe class="rounded w-100 h-100" :src="mapSource" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>
            <!-- Contact Form -->
            <div class="col-lg-6">
              <div class="p-5 rounded contact-form">
                <div class="mb-4">
                  <input type="text" class="form-control border-0 py-3" v-model="form.name" placeholder="Your Name">
                </div>
                <div class="mb-4">
                  <input type="email" class="form-control border-0 py-3" v-model="form.email" placeholder="Your Email">
                </div>
                <div class="mb-4">
                  <input type="text" class="form-control border-0 py-3" v-model="form.project" placeholder="Project">
                </div>
                <div class="mb-4">
                  <textarea class="w-100 form-control border-0 py-3" rows="6" v-model="form.message" placeholder="Message"></textarea>
                </div>
                <div class="text-start">
                  <button class="btn bg-primary text-white py-3 px-5" @click="sendMessage">Send Message</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContactSection',
    data() {
      return {
        contactInfo: [
          { type: 'Address', value: 'Lahore, Punjab, Pakistan', link: 'https://goo.gl/maps/Zd4BCynmTb98ivUJ6', iconClass: 'fas fa-map-marker-alt text-white' },
          { type: 'Call Us', value: '+92 311 567 1234', link: 'tel:+923115671234', iconClass: 'fa fa-phone text-white' },
          { type: 'Email Us', value: 'NativeProgrammers@gmail.com', link: 'NativeProgrammers@gmail.com', iconClass: 'fa fa-envelope text-white' },
        ],
        form: {
          name: '',
          email: '',
          project: '',
          message: ''
        },
        mapSource: 'https://www.google.com/maps/embed?pb=...' // replace with your actual map embed link
      };
    },
    methods: {
      sendMessage() {
        // Handle the form submission, such as validating the input and sending it to a server
        console.log('Sending message:', this.form);
        // You would typically use axios or fetch to send the form data to a server
      }
    }
  }
  </script>
  
  <style scoped>
  /* Your CSS here */
  </style>
  