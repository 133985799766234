<template>
  <div class="container-fluid px-0">
    <div id="carouselId" class="carousel slide" data-bs-ride="carousel">
      <ol class="carousel-indicators">
        <li v-for="(slide, index) in slides" :key="index" :data-bs-target="'#carouselId'" :data-bs-slide-to="index"
          :class="{ active: index === activeIndex }" aria-current="true" :aria-label="`Slide ${index + 1}`"></li>
      </ol>
      <div class="carousel-inner" role="listbox">
        <div v-for="(slide, index) in slides" :key="index"
          :class="{ 'carousel-item': true, active: index === activeIndex }">
          <img :src="slide.image" class="img-fluid" :alt="`Slide ${index + 1}`">
          <div class="carousel-caption">
            <div class="container carousel-content">
              <h6 class="text-secondary h4 animated fadeInUp">{{ slide.title }}</h6>
              <h1 class="text-white display-1 mb-4 animated fadeInRight">{{ slide.headline }}</h1>
              <p class="mb-4 text-white fs-5 animated fadeInDown">{{ slide.description }}</p>
              <router-link to="/about" class="me-2">
                <button type="button"
                  class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">
                  Read More
                </button>
              </router-link>
              <router-link to="/contact" class="ms-2">
                <button type="button"
                  class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">
                  Contact Us
                </button>
              </router-link>

            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev"
        @click="prevSlide">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next"
        @click="nextSlide">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      slides: [
  {
    image: 'img/carousel-1.jpg',
    title: 'Innovative IT Solutions',
    headline: 'Driving Technology Forward',
    description: 'At Native Programmers, we push the boundaries of innovation to develop bespoke software solutions that revolutionize how businesses operate.'
  },
  {
    image: 'img/carousel-2.jpg',
    title: 'Expertise in Digital Services',
    headline: 'Your Trusted Technology Partner',
    description: 'Leverage our comprehensive digital services to enhance your business efficiency and connectivity. Discover why Native Programmers is the partner you need to succeed.'
  }
]

    };
  },
  methods: {
    nextSlide() {
      this.activeIndex = (this.activeIndex + 1) % this.slides.length;
    },
    prevSlide() {
      this.activeIndex = (this.activeIndex + this.slides.length - 1) % this.slides.length;
    }
  }
};
</script>

<style>
/* Custom styles for your carousel */
</style>