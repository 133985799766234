<template>
  <div >
<Carousel/>
<Fact/>
<About/>
<!-- <team/> -->
<Services/>
<!-- <Testimonial/> -->
  </div>
</template>

<script>
import Carousel from '../components/compo/Carousel_C.vue'
import Fact from '../components/compo/Fact_C.vue'
import About from '../components/compo/Aboutstart_C.vue'
import Services from '../components/compo/Services_start.vue'
// import team from '../components/compo/team_members.vue'
// import Testimonial from '../components/compo/Testimonial_C.vue'



export default {
  name: 'home_p',
  components: {
    Carousel,Fact,About,Services
    // ,Testimonial
    // ,team
  } 
}
</script>

<style scoped>

</style>