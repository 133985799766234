<template>
    <div class="container-fluid bg-secondary py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 wow fadeIn" data-wow-delay=".1s">
            <div class="d-flex counter">
              <h1 class="me-3 text-primary counter-value">10</h1>
              <h5 class="text-white mt-1">Success in getting happy customer</h5>
            </div>
          </div>
          <div class="col-lg-3 wow fadeIn" data-wow-delay=".3s">
            <div class="d-flex counter">
              <h1 class="me-3 text-primary counter-value">8</h1>
              <h5 class="text-white mt-1">Successful business</h5>
            </div>
          </div>
          <div class="col-lg-3 wow fadeIn" data-wow-delay=".5s">
            <div class="d-flex counter">
              <h1 class="me-3 text-primary counter-value">10</h1>
              <h5 class="text-white mt-1">Total clients who love us</h5>
            </div>
          </div>
          <div class="col-lg-3 wow fadeIn" data-wow-delay=".7s">
            <div class="d-flex counter">
              <h1 class="me-3 text-primary counter-value">5</h1>
              <h5 class="text-white mt-1">Stars reviews given by satisfied clients</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // import WOW from 'wow.js';
  export default {
    name: 'FactCounter',
    // Here you might use Vue's data function to make the counter dynamic if needed
    data() {
      return {
        // Define your counter data here
      };
    },
    // If using WOW.js for animation, you might want to initialize it in mounted lifecycle hook
    mounted() {
      new WOW().init();
    }
  };
  </script>
  
  <style scoped>
  /* You can add scoped styles for your FactCounter component here */
  </style>
  