import { createRouter, createWebHistory } from 'vue-router';
// src/router/index.js
import HomeP from '../components/home_p.vue'; // Adjust the path to point to the correct file
import abountUs from '../components/abount_us.vue'; // Adjust the path to point to the correct file
import servicesP from '../components/services_p.vue'; // Adjust the path to point to the correct file
import projectsP from '../components/projects_p.vue'; // Adjust the path to point to the correct file
import contactP from '../components/contact_p.vue'; // Adjust the path to point to the correct file
// import Blog from '../Blog.vue';
// import Contact from '../Contact.vue';

const routes = [
  {
    path: '/',
    name: 'home_p',
    component: HomeP
  },
  {
    path: '/about',
    name: 'abountUs',
    component: abountUs
  },
  {
    path: '/services',
    name: 'servicesP',
    component: servicesP
  },
  {
    path: '/projects',
    name: 'projectsP',
    component: projectsP
  },
  {
    path: '/contact',
    name: 'contactP',
    component: contactP
  },
//   {
//     path: '/contact',
//     name: 'Contact',
//     component: Contact
//   },
  // ...other routes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
